import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { INTERNAL_API_URL } from './config';

const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
const toMMddYY = date => {
    return pad(date.getMonth() + 1) +
        "/" + pad(date.getDate()) +
        "/" + pad(date.getFullYear() % 100);
};
const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    style: { backgroundColor: 'lightyellow' }
};
//https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/about.html


const T3EmailColumns = [
    { dataField: "keyid", hidden: true },
    { dataField: 'rcn', sort: true, text: 'RCN', filter: textFilter({ defaultValue: '' }) },
    { dataField: 'account', sort: true, text: 'Account', headerStyle: (colum, colIndex) => { return { width: '200px' }; }, filter: textFilter({ defaultValue: '' }) },
    {
        dataField: 'onboardeddate', sort: true, text: 'Onboard',
        formatter: (cellContent, row) => {
            if (row.onboardedDate !== null) {
                var dt = new Date(row.onboardedDate);
                var fmtTime = toMMddYY(dt);
                return (<span>{fmtTime}</span>)
            }
            else {
                return (<span> </span>)
            }
        }
    },
    { dataField: 'scandataGroupName', sort: true, text: 'Scandata Group', headerStyle: (colum, colIndex) => { return { width: '250px' }; }, filter: textFilter({ defaultValue: '' }) },
    {
        dataField: 'senderEmailAddress', text: 'Sender Email', headerStyle: (colum, colIndex) => { return { width: '220px' }; },
        formatter: (cellContent, row) => {
            var retValue = row.senderEmailAddress.replace("@patronpoints.com", "@patr...");
            return (<span>{retValue}</span>)
        }
    },
    { dataField: 'statusText', sort: true, text: 'Status' },
    { dataField: 'eaivSet', sort: true, text: 'EAIV', headerStyle: (colum, colIndex) => { return { width: '50px' }; } },
    { dataField: 'activityLogSet', text: 'Actv', headerStyle: (colum, colIndex) => { return { width: '50px' }; } },
    { dataField: 'benchmarkSet', text: 'BMark', headerStyle: (colum, colIndex) => { return { width: '50px' }; } },
    { dataField: 'senderEmailSet', text: "Sender" },
    { dataField: 'status', sort: true, text: 'Status', hidden: true }
];

const rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui' style={{border: "thin solid black"} }>
                        <table border="1" cellPadding="10">
                            <tbody>
                                <tr><th>Store</th><th colSpan="5">Scandata Providers</th></tr>
                                {row.storesScandataList.map(({ store_name, scandata_list }) => {
                                    return <tr>
                                        <td>{store_name}</td>
                                        {scandata_list.map(item => {
                                            return (<td>{item.scan_reporter_desc} - {item.scandata_type_desc}</td>)
                                        })}
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        <br />
                        <br />
                        <button onClick={onClose}>Close</button>

                    </div>
                );
            }
        });
    }
};
export class T3Email extends Component {

    constructor(props) {
        super(props);
        this.handlePreview = this.handlePreview.bind(this);
        this.handleTemplateChange = this.handleTemplateChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.hideRows = this.hideRows.bind(this);
        this.handleSetTemplate = this.handleSetTemplate.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);

        this.state =
        {
            hiddenRows: [1, 3, 5, 7],
            step: 0,
            formobjects: { image: '', template: '', templatename: '' },
            loading: false,
            message: "",
            storelist: null,
            FilterUnknown: true,
            FilterComplete: false,
            FilterIneligible: true,
            FilterSender: false,
            FilterAltria: false,
            displayCount: 0,
            templatesLoaded: false,
            showUpdateTemplates: true,
            missingEmailCount: 0,
            emailTemplates: []
        }
    }
    componentDidMount() {
        console.log("componentDidMount in t3Email.js");
        if (this.state.templatesLoaded === false) {
            fetch(INTERNAL_API_URL + '/Email/GetEmailTemplates', {
                method: 'GET'
            })
                .then((response) => {
                    if (!response.ok) {
                        this.setState({ message: "Error reading EmailTemplates: " + JSON.stringify(response) });
                    }
                    else
                        return response.json();
                })
                .then((dtable) => {
                    var templates = dtable
                        .filter(row => row.template_name) // Filter rows where template_name not undefined/null/empty
                        .map(row => ({
                            email_template_id: row.email_template_id || 0,
                            template_name: row.template_name
                        }));

                    this.setState({ emailTemplates: templates, message: "", templatesLoaded: true });
                })
                .catch((error) => {
                    this.setState({ message: error, emailTemplates: [], templatesLoaded: false });
                });
        }
    }

    handleFileChange = e => {
        var fobj = this.state.formobjects;
        fobj[e.target.name] = e.target.files[0];
        this.setState({
            storelist: null,
            formobjects: fobj
        })
    }

    handleTemplateChange = e => {
        var disabled = e.target.selectedOptions[0].text === "Blank";
        var fobj = this.state.formobjects;
        fobj[e.target.name] = e.target.value;
        fobj.templatename = e.target.selectedOptions[0].text;
        this.setState({
            showUpdateTemplates: disabled,
            formobjects: fobj
        })
    }

    handleCheckboxChange = e => {
        var val = e.target.checked;
        switch (e.target.id) {
            case "FilterUnknown":
                this.setState({ FilterUnknown: val });
                break;
            case "FilterComplete":
                this.setState({ FilterComplete: val, FilterAltria: false });
                break;
            case "FilterIneligible":
                this.setState({ FilterIneligible: val, FilterAltria: false });
                break;
            case "FilterSender":
                this.setState({ FilterSender: val });
                break;
            case "FilterAltria":
                if (val === true) {
                    this.setState({ FilterAltria: val, FilterUnknown: true, FilterComplete: false, FilterIneligible: false, FilterSender: false });
                }
                else {
                    this.setState({ FilterAltria: false });
                }
                break;
            default:
                break;
        }
        setTimeout(this.hideRows, 500);
        //this.hideRows(this.state.storelist);
    }

    handlePreview = async e => {
        e.preventDefault();

        this.setState({ loading: true });
        const formData = new FormData();
        var fobj = this.state.formobjects;
        for (let name in this.state.formobjects) {
            formData.append(name, fobj[name]);
        }

        fetch(INTERNAL_API_URL + '/Email/PostTier3', {
            method: 'POST',
            body: formData
        })
            .then((response) => {
                if (!response.ok) {
                    this.setState({ message: response.statusText, storelist: null });
                }
                else {
                    return response.json();

                }
            })
            .then((dobj) => {
                if (dobj.statustext === "SUCCESS") {
                    var filtered = dobj.storelist.filter(function (x) {
                        return (x.statusText === "EMailMissing");
                    });

                    this.setState({ loading: false, storelist: dobj.storelist, message: "", missingEmailCount: filtered.length });
                    this.hideRows(dobj.storelist);
                }
                else {
                    this.setState({ loading: false, storelist: null, message: dobj.statustext });
                }
            })
            .catch((error) => {
                console.log("Catch error: " + JSON.stringify(error));
                this.setState({ loading: false, storelist: null, message: error });
            });
    }

    handleUpdate() {
        this.setState({ loading: true, message: "Updating emails..." });
        var fobj = this.state.formobjects;

        fetch(INTERNAL_API_URL + '/Email/UpdateTier3Templates?templateId=' + fobj.template, {
            method: 'Get'
        })
            .then((response) => {
                //console.log("response.statusText=" + response.statusText);
                if (!response.ok) {
                    this.setState({ message: response.statusText, loading: false });
                }
                else {
                    //console.log("response was OK");
                    return response.json();
                }
            })
            .then((dobj) => {
                this.setState({ message: dobj.statusText, loading: false });
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className='custom-ui' style={{ border: "thin solid black", padding: "10px" }}>
                                <p style={{ textAlign: "center" }}><h2>{dobj.statusText}</h2></p>
                                <p>{dobj.addedCount} Created a new Email Sender Address and selected the new template</p>
                                <p>{dobj.changedCount} Email Sender address already set, changed to the selected template</p>
                                <p>{dobj.unchangedCount} No change - Email Sender address already set and new template already selected</p>
                                <p>{dobj.errorCount} Errors when trying to update the template/email sender</p>
                                <br />
                                <br />
                                <p style={{textAlign: "center"} }>
                                    <button onClick={onClose}>Close</button>
                                </p>

                            </div>
                        );
                    }
                });
            })
            .catch((error) => {
                console.log("Catch error: " + JSON.stringify(error));
                this.setState({ loading: false, message: error });

            });

    }

    hideRows(storelist) {
        if (typeof storelist === "undefined") storelist = this.state.storelist;
        var filtered = [];
        var fcomplete = this.state.FilterComplete;
        var funknown = this.state.FilterUnknown;
        var fsender = this.state.FilterSender;
        var fineligible = this.state.FilterIneligible;

        if (this.state.FilterAltria) {
            filtered = storelist.filter(function (x) {
                return (x.onAltriaSpreadsheet === false);
            });

        }
        else {
            filtered = storelist.filter(function (x) {
                return (fcomplete === false && x.statusText === "Complete") || (funknown === false && x.statusText === "Unknown")
                    || (fsender === false && x.statusText === "EMailMissing")
                    || (fineligible === false && x.statusText === "Ineligible");
            });

        }
        var rows = filtered.map(function (item) { return item.keyid; });
        var displayCount = storelist.length - rows.length;
        this.setState({ displayCount: displayCount, hiddenRows: rows });
    }

    handleSetTemplate = e => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui' style={{ border: "thin solid black", padding: "10px" }}>
                        <p>
                            You are about to change the email template for all eligible stores to <br />
                        </p>
                        <p style={{ paddingLeft: "20px" }}>
                            <b>{this.state.formobjects.templatename}</b>
                        </p>
                        <p>
                            You will also specify a Sender Email address for {this.state.missingEmailCount} stores.
                        </p>
                        <br />
                        <br />
                        <span>
                            <button 
                                onClick={() => {
                                    this.handleUpdate();
                                    onClose();
                                }}
                            >
                                Yes, Update the templates!
                            </button>
                            <button style={{float: "right"}}  onClick={onClose}>Cancel</button>
                        </span>

                    </div>
                )
            }
        });
    }

    render() {
        let bsTable = (this.state.storelist == null) ? ""
            :
            <div style={{ maxHeight: "600px", maxWidth: "1200px", overflowX: "scroll", overflowY: "scroll", float: "left" }}>

                <BootstrapTable columns={T3EmailColumns} data={this.state.storelist} keyField='keyid' bootstrap4={true} striped={true}
                    defaultSorted={this.state.defaultSorted} hiddenRows={this.state.hiddenRows} classes="small-table"
                    filter={filterFactory()}
                    selectRow={selectRow}
                    rowEvents={rowEvents}
                    headerClasses='tr-sticky' />;
            </div>

        let filterTable = (this.state.storelist == null) ? ""
            : <div style={{ float: "left", marginLeft: "10px", fontSize: "16px" }}>
                <p>{this.state.displayCount} Accounts Displayed</p>
                <p>
                    <input type="checkbox" id="FilterUnknown" checked={this.state.FilterUnknown} onChange={this.handleCheckboxChange} />
                    <label htmlFor="FilterUnknown">Show New Accounts</label>
                </p>
                <p>
                    <input type="checkbox" id="FilterIneligible" checked={this.state.FilterIneligible} onChange={this.handleCheckboxChange} />
                    <label htmlFor="FilterIneligible">Ineligible</label>
                </p>
                <p>
                    <input type="checkbox" id="FilterSender" checked={this.state.FilterSender} onChange={this.handleCheckboxChange} />
                    <label htmlFor="FilterSender">Missing Sender Email</label>
                </p>
                <p>
                    <input type="checkbox" id="FilterComplete" checked={this.state.FilterComplete} onChange={this.handleCheckboxChange} />
                    <label htmlFor="FilterComplete">Show Complete Accounts</label>
                </p>
                <p>
                    <input type="checkbox" id="FilterAltria" checked={this.state.FilterAltria} onChange={this.handleCheckboxChange} />
                    <label htmlFor="FilterAltria">On Altria Sheet</label>
                </p>
                <p>
                    <button className="btn btn-primary" disabled={this.state.showUpdateTemplates} onClick={this.handleSetTemplate}>Set Templates</button>
                </p>
            </div>;

        let templatesSelect = (this.state.templatesLoaded === false) ? "Loading...."
            : <select name="template" disabled={this.state.loading} onChange={this.handleTemplateChange}>
                {this.state.emailTemplates.map(emailTemplate => (
                    <option key={emailTemplate.email_template_id} value={emailTemplate.email_template_id}>
                        {emailTemplate.template_name}
                    </option>
                ))}
            </select>;

        return (

            <div style={{ border: "solid thin black" }}>
                <form onSubmit={this.handlePreview} >
                    <table cellPadding="10">
                        <tbody>
                            <tr>
                                <td>1) Pick the spreadsheet containing the Altria Authorized Stores</td>
                                <td>2) Pick the email template</td>
                                <td rowSpan="2">
                                    <input disabled={this.state.loading} type="submit" value="Preview Changes"></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input
                                        name="image"
                                        type="file"
                                        accept="*.xlsx"
                                        style={{ marginLeft: "50px" }}
                                        onChange={this.handleFileChange}>
                                    </input>
                                </td>
                                <td>
                                    {templatesSelect}
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </form>
                <hr />
                <div style={{textAlign: "center", color: "red", fontSize : "large"} }>
                    {this.state.message}
                </div>
                {bsTable}
                {filterTable}
            </div>
        )
    }
}

export default T3Email;