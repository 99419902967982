import React, { Component } from 'react';
//https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Welcome&selectedStory=react%20bootstrap%20table%202%20&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, { textFilter, multiSelectFilter } from 'react-bootstrap-table2-filter';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { TRANS_API_URL } from './config';

import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';

import Form from 'react-validation/build/form'
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import "./common.css";

const shortday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
const toMMddYY = date => {
    return pad(date.getMonth() + 1) +
        "/" + pad(date.getDate()) +
        "/" + pad(date.getFullYear() % 100);
};
const yyyyMMdd = date => {
    return date.getFullYear().toString() + "-" + pad(date.getMonth() + 1) + "-"  + pad(date.getDate());
};
const storeStatusOptions = {
    'New': 'New',
    'Live': 'Live',
    'Archived': 'Archived',
    'Sold': 'Sold',
    'Closed': 'Closed',
    'Unknown': 'Unknown'
};

const storeSummaryOptions = {
    'OK': 'OK',
    'Low': 'Low',
    'Missing': 'Missing'
}

const posTypeOptions = {
    'Passport': 'Passport',
    'Commander': 'Commander',
    'Clover': 'Clover',
    'SmartDESQ': 'SmartDESQ',
    'Radiant': 'Radiant',
    'Pinnacle' : 'Pinnacle'
}
let summary_status_filter;
let store_status_filter;
let account_filter;
let store_filter;
let username_filter;
let postype_filter;

const ScandataPreviewColumns = [
    {
        dataField: 'summary_status', sort: true, text: 'Summary',
        headerStyle: (column, colIndex) => { return { width: '80px' }; },
        headerFormatter: nofilterFormatter,
        filter: multiSelectFilter({
            options: storeSummaryOptions,
            defaultValue: ['Low', 'Missing'],
            getFilter: (filter) => {
                summary_status_filter = filter;
            },
            onFilter: (filterValue) => {
                console.log("filterValue (75) = " + filterValue);
            }
        })
    },
    {
        dataField: 'account_name', sort: true, text: 'Account Name',
        filter: textFilter({
            defaultValue: '',
            getFilter: (filter) => {
                account_filter = filter
            }
        }),
        headerFormatter: nofilterFormatter,
        headerStyle: (column, colIndex) => { return { width: '200px'}; }
    },
    {
        dataField: 'store_name', sort: true, text: 'Store Name',
        headerStyle: (column, colIndex) => { return { width: '200px' }; },
        headerFormatter: nofilterFormatter,
        filter: textFilter({
            defaultValue: '',
            getFilter: (filter) => { store_filter = filter; }
        })
    },
    {
        dataField: 'store_status', sort: true, text: 'Status',
        headerStyle: (column, colIndex) => { return { width: '80px' }; },
        headerFormatter: nofilterFormatter,
        filter: multiSelectFilter({
            options: storeStatusOptions,
            defaultValue: ['Live', 'New'],
            getFilter: (filter) => {
                store_status_filter = filter;
            },
        })
    },
    {
        dataField: 'pos_type', sort: true, text: 'POS Type',
        filter: multiSelectFilter({
            options: posTypeOptions,
            getFilter: (filter) => { postype_filter = filter; }
        }),
        headerFormatter: nofilterFormatter,

    },
    {
        dataField: 'scandata_username', sort: true, text: 'User',
        filter: textFilter({
            getFilter: (filter) => { username_filter = filter; }
        }),
        headerFormatter: nofilterFormatter,

    },
    {

        dataField: "store_id", sort: true, text: "Store ID", hidden: true
    },
    {
        dataField: 'patron_file_location', text: 'File Folder', hidden:true
    },
    {
        dataField: 'bucket_name', text: 'bucket_name', hidden: true
    }
];

function angleHeading(column, colIndex, { sortElement, filterElement }) {
    return (
        <div>
            <div className='rotate' style={{ display: 'flex', flexDirection: 'row' }}>
                {column.text}
            </div>
            <div style={{ display: "none" }}>
                {filterElement}
            </div>
        </div>
    );

}
function nofilterFormatter(column, colIndex, { sortElement, filterElement }) {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            {column.text}
            {sortElement}
            <div style={{ display: "none" }}>
                {filterElement }
            </div>
        </div>
    );
}
function timetext(date)
{
    var fdate = new Date(date);
    return pad(fdate.getHours()) + ":" + pad(fdate.getMinutes()) + ":" + pad(fdate.getSeconds());
}

function cellCountFormatter(cell, row, rowIndex) {

    var newclass = "";
    const matchingTransaction = row.all_transactions.find((t) => t.tobacco_count === cell);
    if (matchingTransaction && matchingTransaction.hasOwnProperty("gapStatusText") )
    {
        if (matchingTransaction["gapStatusText"] === "Missing")
            newclass =  'missingCell'; 
        if (matchingTransaction["gapStatusText"] === "Low")
            newclass =  'lowCell';
    }
    return newclass;
}

function detailRow(row) {
    if (row.files !== null && row.files.length > 0) {
        return (<div style={{ marginLeft: "20px" }}>
            <p>First transaction: {timetext(row.first_trans)} Last transaction: {timetext(row.last_trans)}</p>
            <table>
                <thead>
                    <tr>
                    <th>File Name</th>
                    <th>Count</th>
                        <th>Tobacco</th>
                    </tr>
                </thead>
                <tbody>
                    {row.files.map((file) => <tr><td>{file.filename}</td><td align='right'>{file.trans_count}</td><td align='right'>{file.tobacco_count}</td></tr>)}
                </tbody>
            </table>
        </div>);
    }
    else {
        return (<div style={{ marginLeft: "20px" }}><p>No files found for this date</p></div>)
    }

}

const SDDetailColumns = [
    {
        dataField: 'trans_date', text: 'Date',
        formatter: (cellContent, row) => {
            var dt = new Date(row.trans_date);
            var min = dt.getTimezoneOffset();
            var ndt = new Date(dt.getTime() + min * 60000);
            var fmtTime = toMMddYY(ndt) + " " + shortday[ndt.getDay()];
            return (<span>{fmtTime}</span>)
        },
        headerStyle: (colum, colIndex) => { return { width: '130px' }; }
    },
    { dataField: 'transaction_count', text: 'Count', type: 'number'},
    { dataField: 'tobacco_count', text: 'Tobacco'},
    { dataField: 'gapStatusText', text: 'Status' }
];

const SDFileColumns = [
    { dataField: 'filename', text: 'File', sort: true, headerStyle: (column, colIndex) => { return { width: '340px' }; } },
    {
        dataField: 'lastmodifieddate', sort:true, text: 'Date Received',
            headerStyle: (column, colIndex) => {return { width: '140px' };},
        formatter: (cellContent, row) => {
            var dt = new Date(row.lastmodifieddate);
            var fmtTime = toMMddYY(dt) + " " + pad(dt.getHours()) + ":" + pad(dt.getMinutes());
            return (<span>{fmtTime}</span>)
        }
    },
    { dataField: 'size', text: 'Size', sort: true, type: 'number', align: 'right', headerStyle: (column, colIndex) => {return { width: '80px' };}},
    { dataField: 'posstatus', text: 'Status', sort: true , headerStyle: (column, colIndex) => { return { width: '120px' }; }},
    { dataField: 'posstatustext', text: 'Status Description', sort: true }
];

const SDErrorColumns = [
    { dataField: 'log4net_id', text: 'ID', sort: true, type: 'number', hidden:true, align: 'right' },
    {
        dataField: 'insert_date', sort: true, text: 'Date Received', headerStyle: (column, colIndex) => { return { width: '140px' }; },
        formatter: (cellContent, row) => {
            var dt = new Date(row.insert_date);
            var fmtTime = toMMddYY(dt) + " " + pad(dt.getHours()) + ":" + pad(dt.getMinutes()) + ":" + pad(dt.getSeconds());
            return (<span>{fmtTime}</span>)
        }
    },
    { dataField: 'level', sort: true, text: 'Level', headerStyle: (column, colIndex) => { return { width: '80px' } } },
    { dataField: 'message', sort: true, text: 'message', headerStyle: (column, colIndex) => { return { maxwidth: '400px' } } }
];
const expandRow = {
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnPosition: 'left',
    renderer: (row, rowIndex) => (
        <div style={{ marginLeft: "20px" }}>
            <table style={{ cellPadding: "4px" }}>
                <tbody>
                    <tr>
                        <th>Account ID:</th>
                        <td>{row.account_id}</td>
                        <th>Account Name:</th>
                        <td>{row.account_name}</td>
                        <th>Store ID:</th>
                        <td>{row.store_id}</td>
                        <th>Store Name:</th>
                        <td>{row.store_name}</td>
                    </tr>
                    <tr>
                        <th>Contact:</th><td>{row.contact_name}</td>
                        <th>Altria Reporter:</th>
                        <td>{row.altria_reporter}</td>
                        <th>Altria Active:</th><td>{row.altria_active}</td>
                    </tr>
                    <tr>
                        <th>Contact Phone:</th>
                        <td>{row.contact_phone}</td>
                        <th>RJR Reporter:</th>
                        <td>{row.rjr_reporter}</td>
                        <th>RJR Active:</th><td>{row.rjr_active}</td>
                    </tr>
                    <tr>
                        <th>File Folder</th>
                        <td colSpan="3">{row.patron_file_location}</td>
                        <th>Scandata Group</th>
                        <td colSpan="3">{row.scandata_group_name}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
};

const expandDetailRow = {
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnPosition: 'left',
    renderer: (row, rowIndex) => (
        <div>{detailRow(row)}</div>        
    )
};

export class ScandataPreview extends Component {
    constructor(props) {
        super(props);
        var dt = new Date();
        var sd = new Date().setDate(dt.getDate() - dt.getDay() - 7);        //A week ago last Sunday
        var ed = new Date().setDate(dt.getDate() - dt.getDay());
        if (props.format === "missing") {
            if (dt.getDay() > 2) {
                sd = new Date().setDate(dt.getDate() - dt.getDay());        //Previous Monday
                ed = new Date().setDate(dt.getDate());                  //Tomorrow
            }
        }
        this.state = {
            message: null,
            transactionGaps: null,
            transactionGapsLoading: false,
            dateExpand: null,
            dateExpandLoading: false,
            transDateSelected: null,
            transFileSet: null,
            startDate: sd,
            endDate: ed,
            store_id: 0,
            blurDisplay: "none",
            filterDisplay: "none",
            currenttab: 0,
            fileList: null,
            filelisterror: null,
            logList: null,
            logListError: null,
            storestatuslow: true,
            storestatusmissing: true,
            storestatusok: false,
            accounts: "",
            stores: "",
            storestatenew: true,
            storestatelive: true,
            storestatearchived: false,
            storestateclosed: false,
            storestateunknown: true,
            pospassport: true,
            poscommander: true,
            posclover: true,
            possmartdesq: true,
            posradiant: true,
            pospinnacle: true,
            scandatausers: "",
            scandatagroup: "",
            defaultSorted: [{
                dataField: 'account_name',
                order: 'asc'
            }]
        }
        this.getGapData = this.getGapData.bind(this);
        this.getDetailData = this.getDetailData.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleStoreSelect = this.handleStoreSelect.bind(this);
        this.handleDateSelect = this.handleDateSelect.bind(this);
        this.handleDateRange = this.handleDateRange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.renderSummaryByDate = this.renderSummaryByDate.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
    }

    getGapData(searchOptions) {
        this.setState({
            transactionGapsLoading: true, store_id: 0, dateExpandLoading: false, dateExpand: null, transDateSelected: null, transFileSet: null, blurDisplay: "block",
            accountName: searchOptions.accountName, storeName: searchOptions.storeName, scandataGroup: searchOptions.scandataGroupName
        });
        document.body.style.cursor = 'default';
        var sd = new Date(searchOptions.formStartDate); 
        var ed = new Date(searchOptions.formEndDate);
        sd.setHours(0, 0, 0, 0);            //Previous midnight
        ed.setHours(24, 0, 0, 0);           //Next midnight
        var bodyText = JSON.stringify({
            start_date: sd,
            end_date: ed,
            account_Name: searchOptions.accountName,
            store_name: searchOptions.storeName,
            scandata_group_name: searchOptions.scandataGroupName
        });
        let requestOptions =
        {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: bodyText
        };
        var url = TRANS_API_URL + '/TransactionsNew/GetDailyTransactionGaps';
        fetch(url, requestOptions)
            .then((response) => {
                this.setState({ transactionGapsLoading: false, store_id: 0, blurDisplay: "none" });
                document.body.style.cursor = 'default';
                if (!response.ok) {
                    var messageText = "Error reading GetDailyTransactionGaps: " + response.statusText;
                    confirmAlert({
                        title: 'Error Retrieving Transaction Summary',
                        message: messageText,
                        buttons: [
                            {
                                label: 'Try Again',
                                onClick: () => this.getGapData(searchOptions)
                            },
                            {
                                label: 'Continue',
                            }

                        ]
                    });
                    return null;
                }
                else return response.json();
            })
            .then((dobj) => {
                if (dobj === null || dobj.length === 0) {
                    confirmAlert({
                        title: 'No transactions found',
                        message: "Check your search criteria",
                        buttons: [
                            {
                                label: 'Continue',
                            }

                        ]
                    });

                }
                else {
                    this.setState({ transactionGaps: dobj, message: null });
                }
            })
            .catch((error) => {
                var messageText = "Error reading transaction gaps: " + error;
                confirmAlert({
                    title: 'Error Retrieving Transaction Summary',
                    message: messageText,
                    buttons: [
                        {
                            label: 'Continue',
                        }
                    ]
                });

            });

    }
    getDetailData(row) {
        var store_id = row.store_id;
        var folder = row.patron_file_location.replace("\\", "/");
        this.setState({ dateExpandLoading: true, dateExpand: null, transDateSelected: null, blurDisplay: "block", fileList: null, filelisterror: null, logList: null, logListError: null, currenttab: 0 });
        var sd = new Date(this.state.startDate);
        var ed = new Date(this.state.endDate);
        ed.setDate(ed.getDate() + 1);
        var bucket = row.bucket_name;
        if (bucket === null || bucket === "")
            bucket = "patronpoints";

        var dobj = this.state.transactionGaps.find(val => val.store_id === store_id);
        this.setState({ dateExpand: dobj.all_transactions, dateExpandLoading: false, blurDisplay: "none"  });

        var fileUrl = TRANS_API_URL + '/TransactionsNew/GetStorePhysicalFiles';
        var bodyText = JSON.stringify({
            store_id: store_id,
            bucket: bucket,
            patron_file_location: folder,
            sdtext: '11/09/1953',
            edtext: '11/09/2053'
        });
        let fileRequestOptions =
        {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: bodyText
        };
        fetch(fileUrl, fileRequestOptions)
            .then((response) => {
                if (!response.ok) {
                    this.setState({ filelisterror: response.statusText });
                    return "";
                }
                else return response.json();
            })
            .then((rsp) => {
                this.setState({ fileList: rsp.files });
                if (rsp.status === false) {
                    this.setState({ filelisterror: rsp.statusText });
                }
            })
            .catch((error) => {
                this.setState({ filelisterror: error });
            });

        //***** Get a list of log messages */
        sd.setDate(sd.getDate() -5 );
        ed = new Date();
        ed.setDate(ed.getDate() + 1);

        var messageFilterText = "\\_" + store_id.toString() + "\\_";
        var logBodyText = JSON.stringify({
            Application: "ReceiveScandata",
            startDate: yyyyMMdd(sd),
            endDate: yyyyMMdd(ed),
            messageFilter: messageFilterText
        });

        let logRequestOptions = {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: logBodyText

        };
        var url = TRANS_API_URL + "/TransactionsNew/GetScandataLogEntries";
        fetch(url, logRequestOptions)
            .then((response) => {
                if (!response.ok) {
                    var messageText = "Error reading Log Files: " + JSON.stringify(response);
                    this.setState({ logListError: messageText });
                }
                else return response.json();
            })
            .then((dobj) => {
                this.setState({ logList: dobj.messages, logListError:null });
            })
            .catch((error) => {
                var messageText = "Erorr reading store logs: " + error;
                this.setState({ logListError: messageText });
            });
    }

    handleFilter(e) {
        e.preventDefault();
        this.setState({ "filterDisplay": "block" });
    }
    applyFilter(e) {
        if (e !== null) {
            e.preventDefault();
        }
        this.form.validateAll();
        this.setState({ message: "" });
        var values = this.form.getValues();
        console.log("applyFilter: " + JSON.stringify(values));

        //apply storeSummaryOptions
        var ssarray = [];
        if (values.tbStoreStatusOK === true) ssarray.push("OK");
        if (values.tbStoreStatusLow === true) ssarray.push("Low");
        if (values.tbStoreStatusMissing === true) ssarray.push("Missing");
        if (summary_status_filter) {
            console.log("Summary options: " + ssarray.toString());
            summary_status_filter(ssarray);
        }
        else {
            console.log("Unable to find definition for summary_status_filter");
        }

        ssarray = [];
        if (values.tbStoreStateNew === true) ssarray.push("New");
        if (values.tbStoreStateLive === true) ssarray.push("Live");
        if (values.tbStoreStateArchived === true) ssarray.push("Archived");
        if (values.tbStoreStateClosed === true) ssarray.push("Closed");
        if (values.tbStoreStateUnknown === true) ssarray.push("Unknown");
        store_status_filter(ssarray);

        ssarray = [];
        if (values.tbPOSPassport === true) ssarray.push("Passport");
        if (values.tbPOSCommander === true) ssarray.push("Commander");
        if (values.tbPOSClover === true) ssarray.push("Clover");
        if (values.tbPOSSmartdesq === true) ssarray.push("SmartDESQ");
        if (values.tbPOSRadiant === true) ssarray.push("Radiant");
        if (values.tbPOSPinnacle === true) ssarray.push("Pinnacle");
        postype_filter(ssarray);


        account_filter(values.tbAccountList);
        store_filter(values.tbStoreList);
        username_filter(values.tbScandataUsers);

        this.setState({ "filterDisplay": "none" });
    }

    handleCheckbox(e) {
        switch (e.currentTarget.name) {
            case "cbStoreStatusOK":
                this.setState({ "storestatusok": e.currentTarget.checked });
                break;
            case "cbStoreStatusLow":
                this.setState({ "storestatuslow": e.currentTarget.checked });
                break;
            case "cbStoreStatusMissing":
                this.setState({ "storestatusmissing": e.currentTarget.checked });
                break;
            case "cbStoreStateNew":
                this.setState({ "storestatenew": e.currentTarget.checked });
                break;
            case "cbStoreStateLive":
                this.setState({ "storestatelive": e.currentTarget.checked });
                break;
            case "cbStoreStateArchived":
                this.setState({ "storestatearchived": e.currentTarget.checked });
                break;
            case "cbStoreStateClosed":
                this.setState({ "storestateclosed": e.currentTarget.checked });
                break;
            case "cbStoreStateUnknown":
                this.setState({ "storestateunknown": e.currentTarget.checked });
                break;
            case "cbPOSPassport":
                this.setState({ "pospassport": e.currentTarget.checked });
                break;
            case "cbPOSCommander":
                this.setState({ "poscommander": e.currentTarget.checked });
                break;
            case "cbPOSClover":
                this.setState({ "posclover": e.currentTarget.checked });
                break;
            case "cbPOSSmartdesq":
                this.setState({ "possmartdesq": e.currentTarget.checked });
                break;
            case "cbPOSRadiant":
                this.setState({ "posradiant": e.currentTarget.checked });
                break;
            case "cbPOSPinnacle":
                this.setState({ "pospinnacle": e.currentTarget.checked });
                break;
            default:
                break;
        }
    }

    renderFilterForm() {
        return (

            <div id="filter" style={{ display: this.state.filterDisplay }}>
                <div style={{ backgroundColor: "white", marginLeft: "800px", marginTop: "100px", width: "900px", float: "left", border: "solid thin black" }} className="form-group">
                    <Form ref={c => { this.form = c; }}>

                        <div className="form-group" style={{ display: "none" }}>
                            <Input
                                className="form-control"
                                name="tbStoreStatusOK"
                                value={this.state.storestatusok}
                            />
                            <Input
                                className="form-control"
                                name="tbStoreStatusLow"
                                value={this.state.storestatuslow}
                            />
                            <Input
                                className="form-control"
                                name="tbStoreStatusMissing"
                                value={this.state.storestatusmissing}
                            />
                            <Input
                                className="form-control"
                                name="tbStoreStateNew"
                                value={this.state.storestatenew}
                            />
                            <Input
                                className="form-control"
                                name="tbStoreStateLive"
                                value={this.state.storestatelive}
                            />
                            <Input
                                className="form-control"
                                name="tbStoreStateArchived"
                                value={this.state.storestatearchived}
                            />
                            <Input
                                className="form-control"
                                name="tbStoreStateClosed"
                                value={this.state.storestateclosed}
                            />
                            <Input
                                className="form-control"
                                name="tbStoreStateUnknown"
                                value={this.state.storestateunknown}
                            />
                            <Input
                                className="form-control"
                                name="tbPOSPassport"
                                value={this.state.pospassport}
                            />
                            <Input
                                className="form-control"
                                name="tbPOSCommander"
                                value={this.state.poscommander}
                            />
                            <Input
                                className="form-control"
                                name="tbPOSClover"
                                value={this.state.posclover}
                            />
                            <Input
                                className="form-control"
                                name="tbPOSSmartdesq"
                                value={this.state.possmartdesq}
                            />
                            <Input
                                className="form-control"
                                name="tbPOSRadiant"
                                value={this.state.posradiant}
                            />
                            <Input
                                className="form-control"
                                name="tbPOSPinnacle"
                                value={this.state.pospinnacle}
                            />
                            <Input
                                className="form-control"
                                name="tbScandataGroup"
                                value={this.state.scandatagroup}
                            />


                        </div>

                        <table style={{ width: "800px" }}>
                            <tbody>
                                <tr>
                                    <th>Readiness</th>
                                    <td>OK: <input type="checkbox" name="cbStoreStatusOK" value="OK" defaultChecked={this.state.storestatusok} onChange={(cb) => this.handleCheckbox(cb)} /></td>
                                    <td>Low: <input type="checkbox" name="cbStoreStatusLow" value="Low" defaultChecked={this.state.storestatuslow} onChange={(cb) => this.handleCheckbox(cb)} /></td>
                                    <td>Missing: <input type="checkbox" name="cbStoreStatusMissing" value="Missing" defaultChecked={this.state.storestatusmissing} onChange={(cb) => this.handleCheckbox(cb)} /></td>
                                </tr>
                                <tr>
                                    <td colSpan="4"><hr /></td>
                                </tr>
                                <tr>
                                    <th>Accounts</th>
                                    <td colSpan="4">
                                        <Input
                                            className="form-control"
                                            name="tbAccountList"
                                            columns="80"
                                            value={this.state.accounts}
                                        />
                                    </td>
                                    <td>&nbsp;</td>
                                    <th>Store Status</th>
                                    <td>New: <input type="checkbox" name="cbStoreStateNew" value="OK" defaultChecked={this.state.storestatenew} onChange={(cb) => this.handleCheckbox(cb)} /></td>
                                    <td>Live: <input type="checkbox" name="cbStoreStateLive" value="OK" defaultChecked={this.state.storestatelive} onChange={(cb) => this.handleCheckbox(cb)} /></td>
                                    <td>Unknown: <input type="checkbox" name="cbStoreStateUnknown" value="OK" defaultChecked={this.state.storestateunknown} onChange={(cb) => this.handleCheckbox(cb)} /></td>
                                </tr>
                                <tr>
                                    <td colSpan="6"><hr /></td>
                                    <td></td>
                                    <td>Sold: <input type="checkbox" name="cbStoreStateSold" value="OK" defaultChecked={this.state.storestatesold} onChange={(cb) => this.handleCheckbox(cb)} /></td>
                                    <td>Closed: <input type="checkbox" name="cbStoreStateClosed" value="OK" defaultChecked={this.state.storestateclosed} onChange={(cb) => this.handleCheckbox(cb)} /></td>
                                    <td>Archived: <input type="checkbox" name="cbStoreStateArchived" value="OK" defaultChecked={this.state.storestatearchived} onChange={(cb) => this.handleCheckbox(cb)} /></td>
                                </tr>
                                <tr>
                                    <th>Stores</th>
                                    <td colSpan="3">
                                        <Input
                                            className="form-control"
                                            name="tbStoreList"
                                            value={this.state.stores}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="5"><hr /></td>
                                    <td>&nbsp;</td>
                                    <th>POS Types</th>
                                    <td>Passport: <input type="checkbox" name="cbPOSPassport" value="OK" defaultChecked={this.state.pospassport} onChange={(cb) => this.handleCheckbox(cb)} /></td>
                                    <td>Commander: <input type="checkbox" name="cbPOSCommander" value="OK" defaultChecked={this.state.poscommander} onChange={(cb) => this.handleCheckbox(cb)} /></td>
                                    <td>Clover: <input type="checkbox" name="cbPOSClover" value="OK" defaultChecked={this.state.posclover} onChange={(cb) => this.handleCheckbox(cb)} /></td>
                                </tr>
                                <tr>
                                    <td colSpan="5"><hr /></td>
                                    <td>&nbsp;</td>
                                    <td/>
                                    <td>SmartDesq: <input type="checkbox" name="cbPOSSmartdesq" value="OK" defaultChecked={this.state.possmartdesq} onChange={(cb) => this.handleCheckbox(cb)} /></td>
                                    <td>Radiant: <input type="checkbox" name="cbPOSRadiant" value="OK" defaultChecked={this.state.posradiant} onChange={(cb) => this.handleCheckbox(cb)} /></td>
                                    <td>Pinnacle: <input type="checkbox" name="cbPOSPinnacle" value="OK" defaultChecked={this.state.pospinnacle} onChange={(cb) => this.handleCheckbox(cb)} /></td>
                                </tr>
                                <tr>
                                    <th>Users</th>
                                    <td colSpan="3">
                                        <Input
                                            className="form-control"
                                            name="tbScandataUsers"
                                            value={this.state.scandatausers}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="4"><hr /></td>
                                </tr>
                                <tr>
                                    <td colSpan="6" align="center">
                                        <div className="form-group" style={{ zIndex: "999" }}>
                                            <CheckButton onClick={this.applyFilter}
                                                className="btn btn-primary btn-block btn-fixed-width">
                                                <span>Apply Filter</span>
                                            </CheckButton>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </Form>
                </div>
            </div>
        );
    }
    handleSearch(e) {
        e.preventDefault();
        this.form.validateAll();
        this.setState({ message: "" });
        var values = this.form.getValues();
        console.log(values);
        this.getGapData(values);
    }

     handleDateRange(date, dtidx) {
        if (dtidx === 0) this.setState({ startDate: date });
        else this.setState({ endDate: date });
    }

    handleButtonClick() {
        this.setState({ transactionGaps: null, store_id: 0, transactionGapsLoading: false, dateExpand: null, dateExpandLoading: false });
        //this.getGapData();
    }

    handleStoreSelect = (childData) => {
        this.setState({ store_id: childData.store_id });
        this.getDetailData(childData);
    }

    handleDateSelect = (childData) => {
        if (this.state.dateExpand != null) {
            var fidx = this.state.dateExpand.findIndex(x => x.trans_date === childData.trans_date);
            if (fidx >= 0) {
                this.setState({ transDateSelected: childData.trans_date, transFileSet: this.state.dateExpand[fidx].files })
            }
            else {
                this.setState({ transDateSelected: childData.trans_date, transFileSet: null })
            }
        }
        else {
            this.setState({ transDateSelected: childData.trans_date, transFileSet: null })
        }
    }

    renderFileList() {
        if (this.state.store_id === 0 || this.state.transactionGapsLoading === true || this.state.dateExpandLoading === true) return "";
        if (this.state.fileList === null) return (<p>File list still loading...</p>);
        if (this.state.filelisterror !== null && this.state.filelisterror.length > 0) return (<p>{this.state.filelisterror}</p>);
        if (this.state.fileList.length === 0) return (<p>No files found</p>);
        return (<div style={{ marginLeft: "50px", maxHeight: "400px", overflow: "auto" }}><BootstrapTable columns={SDFileColumns} data={this.state.fileList} keyField='filename' bootstrap4={true} striped={true} classes="small-table"
            headerClasses='tr-sticky' /></div>);
    }

    renderLogList() {
        if (this.state.store_id === 0 || this.state.transactionGapsLoading === true || this.state.dateExpandLoading === true) return "";
        if (this.state.logListError !== null) return (<p>{this.state.logListError}</p>);
        if (this.state.fileList === null) return (<p>Error list still loading...</p>);
        if (this.state.logList === null || this.state.logList.length === 0) return (<p>No process history records found</p>);
        return (<div style={{ marginLeft: "50px" }}><BootstrapTable columns={SDErrorColumns} data={this.state.logList} keyField='log4net_id' bootstrap4={true} striped={true} classes="small-table"
            headerClasses='tr-sticky' /></div>);
    }

    renderDetail() {
        if (this.state.store_id === 0 || this.state.transactionGapsLoading === true || this.state.dateExpandLoading === true) return "";
        var stidx = this.state.transactionGaps.findIndex(x => x.store_id === this.state.store_id);
        if (stidx < 0) return <p>Unable to find store {this.state.store_id}</p>;
        let fileList = this.renderFileList();
        let logList = this.renderLogList();

        return <Tabs selectedIndex={this.state.currenttab} onSelect={newIndex => this.setState({ currenttab: newIndex, lastupdatestatus: "" })}>
            <TabList>
                <Tab>File List</Tab>
                <Tab>Process History</Tab>
            </TabList>
            <TabPanel>{fileList}</TabPanel>
            <TabPanel>{logList}</TabPanel>
            </Tabs>

    }

    renderSummaryByDate() {
        if (this.state.store_id === 0 || this.state.transactionGapsLoading === true || this.state.dateExpandLoading === true) return "";
        var stidx = this.state.transactionGaps.findIndex(x => x.store_id === this.state.store_id);
        if (stidx < 0) return <p>Unable to find store {this.state.store_id}</p>;
        let gdata = this.state.transactionGaps[stidx];
        return (<div style={{ float: "left" }}>
            <div style={{ marginLeft: "30px", float: "left", maxWidth: "900px" }}>
                <table>
                    <tbody>
                        <tr>
                            <td colSpan="2"> <h2>{gdata.store_name}</h2></td>
                        </tr>
                        <tr>
                            <td>
                                <div style={{ maxWidth: "450px", maxHeight: "400px" }}>
                                    <BootstrapTable columns={SDDetailColumns} data={gdata.all_transactions} keyField='trans_date' bootstrap4={true} condensed={true} striped={true} classes="small-table"
                                        noDataIndication="No transactions found"
                                        expandRow={expandDetailRow}
                                        headerClasses='tr-sticky' />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>);
    }

    renderButton() {
        if (this.state.transactionGaps === null) return "";
        return <span style={{ marginLeft: "10px" }}>
            <button style={{ marginLeft: "10px" }} onClick={this.handleButtonClick} className="btn btn-primary btn-block">Change Search</button>
            <button style={{ marginLeft: "10px" }} onClick={this.handleFilter} className="btn btn-primary btn-block">Filter Display</button>
        </span> 
    }

    render() {
        var smc = [
            {
                dataField: 'summary_status', sort: true, text: 'Summary',
                filter: multiSelectFilter({
                    options: storeSummaryOptions,
                    defaultValue: ['Low', 'Missing'],
                    getFilter: (filter) => {
                        summary_status_filter = filter;
                    },
                    onFilter: (filterValue) => {
                        console.log("filterValue (936) = " + filterValue);
                    }
                }),
                headerStyle: (column, colIndex) => { return { width: '80px' }; },
                headerFormatter: nofilterFormatter
            },
            {
                dataField: 'account_name', sort: true, text: 'Account Name',
                filter: textFilter({
                    defaultValue: '',
                    getFilter: (filter) => {
                        account_filter = filter
                    }
                }),
                headerStyle: (column, colIndex) => { return { width: '200px' }; },
                headerFormatter: nofilterFormatter
            },
            {
                dataField: 'store_name', sort: true, text: 'Store Name',
                headerStyle: (column, colIndex) => { return { width: '200px' }; },
                headerFormatter: nofilterFormatter,
                filter: textFilter({
                    defaultValue: '',
                    getFilter: (filter) => { store_filter = filter; }
                })
            },
            {
                dataField: 'store_status', sort: true, text: 'Status',
                headerStyle: (column, colIndex) => { return { width: '80px' }; },
                headerFormatter: nofilterFormatter,
                filter: multiSelectFilter({
                    options: storeStatusOptions,
                    defaultValue: ['Live', 'New'],
                    getFilter: (filter) => {
                        store_status_filter = filter;
                    }
                })
            },
            {
                dataField: 'pos_type', sort: true, text: 'POS Type',
                headerStyle: (column, colIndex) => { return { width: '120px' }; },
                filter: multiSelectFilter({
                    options: posTypeOptions,
                    getFilter: (filter) => { postype_filter = filter; }
                }),
                headerFormatter: nofilterFormatter,

            },
            {
                dataField: 'scandata_username', sort: true, text: 'User',
                headerStyle: (column, colIndex) => { return { width: '100px' }; },
                filter: textFilter({
                    getFilter: (filter) => { username_filter = filter; }
                }),
                headerFormatter: nofilterFormatter,

            }
        ];
        if (this.state.transactionGaps != null) {
            this.state.transactionGaps[0].all_transactions.map((t, idx) => {
                var fieldName = 'all_transactions[' + idx.toString() + '].tobacco_count';
                var dt = new Date(t.trans_date);
                var offset = dt.getTimezoneOffset() / 60;
                var hours = dt.getHours();

                dt.setHours(hours + offset);
                var heading = toMMddYY(new Date(dt));
                smc.push({
                    dataField: fieldName, text: heading, type: 'number', headerFormatter: angleHeading,
                    classes: (cell, row, rowIndex, colIndex) => { return cellCountFormatter(cell, row, rowIndex)  } });
                return { dataField: fieldName, text: "HDG", type: 'number', headerFormatter: angleHeading };
            });
        //smc.push({dataField: 'all_transactions[6].tobacco_count', text: '1/20/24', type: 'number', headerFormatter: angleHeading});

        }

        
        let rb = this.renderButton();
        let summarybydate = this.renderSummaryByDate();
        let bsTable = (this.state.transactionGaps == null) ? 
            <div>
                <Form ref={c => { this.form = c; }}>

                    <div className="form-group" style={{ display: "none" }}>
                        <Input
                            className="form-control"
                            name="formStartDate"
                            value={this.state.startDate}
                        />           
                        <Input
                            className="form-control"
                            name="formEndDate"
                            value={this.state.endDate}
                        />           

                    </div>
                    <table>
                        <thead>
                            <tr><th colSpan="2">{this.props.format === "missing" ? "Search for stores missing transactions" : "Search for Transaction Data"}</th></tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Date range:</td>
                                <td>
                                    <DatePicker selected={this.state.startDate} onChange={(date) => this.handleDateRange(date, 0)} 
                                            dateFormat="MM/dd/yyyy"></DatePicker>
                                </td>
                                <td> - </td>
                                <td>
                                    <DatePicker selected={this.state.endDate} onChange={(date) => this.handleDateRange(date, 1)} 
                                            dateFormat="MM/dd/yyyy"></DatePicker>
                                </td>
                            </tr>
                            <tr>
                                <td>Account:</td>
                                <td colSpan="3">
                                    <div className="form-group">
                                        <Input
                                            type="text"
                                            className="form-control"
                                            name="accountName"
                                            value={this.state.accountName }
                                            tabIndex="1"
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Store:</td>
                                <td colSpan="3">
                                    <div className="form-group">
                                        <Input
                                            type="text"
                                            className="form-control"
                                            name="storeName"
                                            value={this.state.storeName }
                                            tabIndex="1"
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Scandata Group:</td>
                                <td colSpan="3">
                                    <div className="form-group">
                                        <Input
                                            type="text"
                                            className="form-control"
                                            name="scandataGroupName"
                                            value={this.state.scandataGroup}
                                            tabIndex="1"
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="4">
                                    <CheckButton onClick={this.handleSearch}
                                        className="btn btn-primary btn-block btn-fixed-width"
                                        disabled={this.state.transactionGapsLoading}>
                                        <span>Search</span>
                                    </CheckButton>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Form>

            </div>

            :
            (this.props.format === "missing") ?
            <div>
                <div style={{ maxHeight: "600px", maxWidth: "1200px", overflowY: "scroll", float: "left" }}>
                    <BootstrapTable columns={smc} data={this.state.transactionGaps} keyField='store_id' bootstrap4={true} striped={true} classes="small-table"
                        defaultSorted={this.state.defaultSorted}
                        noDataIndication="No matching stores - Check search criteria and filter"
                        filter={filterFactory()}
                            rowEvents={
                                {
                                    onClick: (e, row, rowIndex) => {
                                        this.handleStoreSelect(row);
                                    }
                                }
                            }                        expandRow={expandRow}
                        headerClasses='tr-sticky' />
                </div>
            </div>
            :
                <div>
                    <div style={{ maxHeight: "600px", maxWidth: "800px", overflowY: "scroll", float: "left" }}>
                        <BootstrapTable columns={ScandataPreviewColumns} data={this.state.transactionGaps} keyField='store_id' bootstrap4={true} striped={true} classes="small-table"
                            defaultSorted={this.state.defaultSorted}
                            noDataIndication="No matching stores - Check search criteria and filter"
                            filter={filterFactory()}
                            rowEvents={
                                {
                                    onClick: (e, row, rowIndex) => {
                                        this.handleStoreSelect(row);
                                    }
                                }
                            }
                            expandRow={expandRow}
                            headerClasses='tr-sticky' />
                    </div>
                    {summarybydate}
                </div>

        let detailTable = this.renderDetail();
        let filterForm = this.renderFilterForm();
        return (
            <div>
                <div id="blur" style={{ display: this.state.blurDisplay }}>
                </div>
                {filterForm}
                <div style={{ minWidth: "1400px", maxWidth:"700px"}}>
                    <span style={{ fontSize: "1.5em", fontWeight: "bold" }}>{this.props.format === "missing" ? "Missing Transactions" : "Scandata Preview"} {rb}</span>
                    <hr />
                    <div style={{ textAlign: "center", color: "red", fontSize: "large" }}>
                        {this.state.message}
                    </div>
                    <SplitterLayout vertical={true} >
                        {bsTable}
                        {detailTable}
                    </SplitterLayout>
                </div>
            </div>
        );
    }
}
export default ScandataPreview;