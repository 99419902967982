import React, { useState, useEffect } from "react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Alert from 'react-bootstrap/Alert';
import "./common.css";
import './Report.css';
import { CRM_API_URL, INTERNAL_API_URL } from './config';

const JbfReport = () => {
    const successAlertText = "Report email has been scheduled for sending";

    const getReportConfig = () => {
        const url = INTERNAL_API_URL + '/Reports/GetJbfReportConfig'
        return fetch(url).then((res) => {
            if (res.ok)
                return res.json().then((config) => {
                    return config;
                })
            else
                return res.text().then((errorText) => {
                    throw new Error(errorText);
                });
        });
    };

    const getEmailRecipients = (account_id) => {
        let url = CRM_API_URL + '/Account/SelectContactsByAccountID/' + account_id;
        return fetch(url).then((res) => { 
            if (res.ok)
                return res.json().then(contacts => {
                    let email_recipients = contacts
                        .filter(x => x.email_addr && x.report_type_codes && x.report_type_codes.includes("JBFReport"))
                        .map(x => x.email_addr).join(';');
                    return email_recipients;
                });
            else
                return res.text().then((errorText) => {
                    throw new Error(errorText);
                });                
        })
    };

    const getPromos = (account_id, start_date, end_date, promo_types, search_pattern) => {        
        const url = INTERNAL_API_URL + '/Reports/GetPromotions'

        let request = {
            account_id: account_id,
            start_date: start_date.toISOString(),
            end_date: end_date.toISOString(),
            promo_types: promo_types,
            search_pattern: search_pattern
        };

        let requestOptions =
        {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        };

        return fetch(url, requestOptions).then((res) => {
            if (res.ok)
                return res.json();
            else
                return res.text().then((errorText) => {
                    throw new Error(errorText);
                });
        }).then((promos) => {
            return promos.map((promo) => {
                return {
                    promo_ids: promo.promo_ids,
                    promo_name: promo.promo_name,
                    selected: true
                }
            });
        });
    };

    const getLastSunday = () => {
        const dt = new Date();
        const dayOfWeek = dt.getDay();
        if (dayOfWeek !== 0) {
            const diffDays = dayOfWeek;
            dt.setDate(dt.getDate() - diffDays);            
        }

        return dt;
    };

    const handleAccountChange = (account_id) => {
        setAccountId(account_id);
        setShowBlur(true);
        getEmailRecipients(account_id)
            .then((email_recipients) => {
                setShowBlur(false);
                setEmailRecipients(email_recipients);
            })
            .catch((error) => {
                setShowBlur(false);
                showErrorAlert(error.message);
            });
    }

    const handleCheckAllPromoItemsCheckboxChange = () => {
        let new_selected = promo_list.some((promo) => !promo.selected);
        let new_promo_list = promo_list.map((promo) => {
            return {
                promo_ids: promo.promo_ids,
                promo_name: promo.promo_name,
                selected: new_selected
            };
        });

        setPromoList(new_promo_list);
    };

    const handlePromoItemCheckboxChange = (promo_ids) => {
        let new_promo_list = promo_list.map((promo) => {
            let idsEqual = JSON.stringify(promo.promo_ids) === JSON.stringify(promo_ids);
            return {
                promo_ids: promo.promo_ids,
                promo_name: promo.promo_name,
                selected: idsEqual ? !promo.selected : promo.selected
            };
        });

        setPromoList(new_promo_list);
    };

    const handleApplyFilters = () => {
        if (start_date > end_date) {
            showWarningAlert("Please pick valid date range");
            return;
        }

        setShowBlur(true);
        getPromos(account_id, start_date, end_date, promo_types, search_pattern).then((promos) => {
            setPromoList(promos);
            setShowBlur(false);
        }).catch((error) => {
            setShowBlur(false);
            showErrorAlert(error.message);
        });
    };

    const handleSendReport = () => {
        if (!promo_list.length || !promo_list.some((item) => item.selected)) {
            showWarningAlert("Please select at least one promotion");
            return;
        }

        if (!email_recipients.length) {
            showWarningAlert("Please enter email recipients");
            return;
        }

        const url = INTERNAL_API_URL + "/Reports/SendJbfReport";

        let promo_ids = promo_list.reduce((accumulator, current_promo) => { return accumulator.concat(current_promo.promo_ids) }, []);
        promo_ids = Array.from(new Set(promo_ids)); // Remove duplicates

        let request = {
            account_ids: [account_id],
            start_date: start_date.toISOString(),
            end_date: end_date.toISOString(),
            promo_ids: promo_ids,
            email_recipients: email_recipients,
            email_bcc_recipients: email_bcc_recipients
        };

        let requestOptions =
        {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        };

        setShowBlur(true);
        fetch(url, requestOptions).then((res) => {
            if (res.ok) {
                setShowBlur(false);
                showEmailScheduledAlert();
            } else return res.text().then((errorText) => {
                throw new Error(errorText);
            });
        }).catch((error) => {
            setShowBlur(false);
            showErrorAlert(error.message);
        });
    };

    const showAlert = (text, variant) => {
        setAlertText(text);
        setAlertVariant(variant);
        setShowAlert(true);

        let timeout_id = setTimeout(() => {
            setShowAlert(false);
            setTimeoutId(null);
        }, 5000);
        setTimeoutId(timeout_id);
    }

    const showEmailScheduledAlert = () => {
        showAlert(successAlertText, "success");
    }

    const showWarningAlert = (text) => {
        showAlert(text, "warning");
    }

    const showErrorAlert = (text) => {
        showAlert(text, "danger");
    }

    let endDateValue = getLastSunday();
    endDateValue.setHours(14);
    let startDateValue = new Date(endDateValue.valueOf());
    startDateValue.setDate(endDateValue.getDate() - 6);
    startDateValue.setHours(14);

    const [all_accounts, setAllAccounts] = useState([]);
    const [account_id, setAccountId] = useState(0);
    const [email_recipients, setEmailRecipients] = useState("");
    const [email_bcc_recipients, setEmailBccRecipients] = useState("");
    const [start_date, setStartDate] = useState(startDateValue);
    const [end_date, setEndDate] = useState(endDateValue);
    const [promo_types, setPromoTypes] = useState("All");
    const [search_pattern, setSearchPattern] = useState("");
    const [show_blur, setShowBlur] = useState(true);
    const [show_alert, setShowAlert] = useState(false);
    const [alert_variant, setAlertVariant] = useState("success");
    const [alert_text, setAlertText] = useState(successAlertText);
    const [timeout_id, setTimeoutId] = useState(null);

    useEffect(() => {
        getReportConfig().then((report_config) => {
            setAllAccounts(report_config.all_accounts);
            setAccountId(report_config.account_id);
            setEmailBccRecipients(report_config.email_bcc_recipients);
            setSearchPattern(report_config.search_pattern);
            return report_config;
        }).then((report_config) => {
            return Promise.all([
                getEmailRecipients(report_config.account_id),
                getPromos(report_config.account_id, start_date, end_date, promo_types, report_config.search_pattern)]);
        }).then(([email_recipients, promos]) => {
            setEmailRecipients(email_recipients);
            setPromoList(promos);
            setShowBlur(false);
        }).catch((error) => {
            setShowBlur(false);
            showErrorAlert(error.message);
        });

        return () => {
            if (timeout_id)
                clearTimeout(timeout_id);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [promo_list, setPromoList] = useState([]);

    return (
        <div>
            <div id="blur" style={{ display: show_blur ? "block" : "none" }}></div>
            <div className="control-group">
                <div className="control-title">Account</div>
                <select className="control-input" value={account_id} onChange={(event) => handleAccountChange(event.target.value)}>
                    {all_accounts.map((account) => (
                        <option key={account.account_id} value={account.account_id}>{ account.account_name }</option>
                    ))}                    
                </select>
            </div>
            <div className="control-group">
                <div className="control-title">Email recipients</div>
                <div>
                    <input className="control-input" type="text" value={email_recipients} onChange={(event) => setEmailRecipients(event.target.value)}></input>
                </div>
            </div>
            <div className="control-group">
                <div className="control-title">Email Bcc recipients</div>
                <div>
                    <input className="control-input" type="text" value={email_bcc_recipients} onChange={(event) => setEmailBccRecipients(event.target.value)}></input>
                </div>
            </div>
            <table className="control-group">
                <thead>
                    <tr>
                        <th className="control-title" colSpan="2">Date range</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <span>From</span>
                            <DatePicker className="date-picker" selected={start_date} dateFormat="MM/dd/yyyy" onChange={(date) => { date.setHours(14); setStartDate(date); }} />
                        </td>
                        <td>
                            <span>To</span>
                            <DatePicker className="date-picker" selected={end_date} dateFormat="MM/dd/yyyy" onChange={(date) => { date.setHours(14); setEndDate(date); }} />
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="control-group">
                <div className="control-title">Promo types</div>
                <select value={promo_types} onChange={(event) => setPromoTypes(event.target.value)}>
                    <option key="PVD" value="PVD">PVD</option>
                    <option key="All" value="All">All</option>
                </select>
            </div>
            <div className="control-group">
                <div className="control-title">Search pattern</div>
                <div>
                    <input className="control-input" type="text" value={search_pattern} onChange={(event) => setSearchPattern(event.target.value)}></input>
                </div>
            </div>
            <div>
                <button onClick={handleApplyFilters}>Apply filters</button>
            </div>
            <div className="control-group">
                <div className="control-title">Promotions</div>
                <ul className="itemList">
                    <li key="check_all">
                        <label>
                            <input type="checkbox" checked={promo_list.length && promo_list.every((item) => item.selected)} onChange={handleCheckAllPromoItemsCheckboxChange} />
                            Check All
                        </label>
                    </li>
                    {promo_list.map((item) => (
                        <li key={item.promo_ids.join(',')}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={item.selected}
                                    onChange={() => handlePromoItemCheckboxChange(item.promo_ids)}
                                />
                                {item.promo_name}
                            </label>
                        </li>
                    ))}                    
                </ul>
            </div>
            <div>
                <button onClick={handleSendReport}>Send report email</button>
            </div>
            <Alert className="alert-msg" key={alert_variant} variant={alert_variant} show={show_alert} onClose={() => setShowAlert(false)} dismissible>{alert_text}</Alert>
        </div>
    );
}

export default JbfReport;