import React, { useState } from "react";
import Calendar from 'moedim'
import { INTERNAL_API_URL } from './config';

//https://reactjsexample.com/a-small-reanpm ct-package-for-picking-a-single-date-from-a-calendar/

const App = () => {
    const [currentDate, setCurrentDate] = useState(new Date())
    const handleClick = event => {
        event.preventDefault();
        var runDate = new Date();
        var url = INTERNAL_API_URL + '/Reports/GetLFPReport?startTimeText=' + encodeURIComponent((currentDate.getMonth() + 1) + "/" + currentDate.getDate() + "/" + currentDate.getFullYear()) + "&runtime=" + encodeURIComponent(runDate.getTime());
        //fetch(url, { method: 'GET' })

        const link = document.createElement('a');
        link.href = url;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }


    return (
        <div>
            <center>
                <h1>Loyalty Allowance Program Report</h1>
                <h4>Pick Starting Date</h4>
                <Calendar value={currentDate} onChange={(d) => setCurrentDate(d)} />
                <br />
                <button onClick={handleClick}>Run Report</button>
            </center>
        </div>
    );
};

export default App;