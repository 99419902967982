import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//class CustomAccordion extends React.Component {
//    constructor(props) {
//        super(props);
//    }

//    render() {
//        return (
//            <div>
//                <Accordion>
//                    <AccordionSummary
//                        expandIcon={<ExpandMoreIcon />}
//                        aria-controls="panel1a-content"
//                        id="panel1a-header"
//                    >
//                        <Typography>Accordion 1</Typography>
//                    </AccordionSummary>
//                    <AccordionDetails>
//                        <Typography>
//                            { props.text }
//                        </Typography>
//                    </AccordionDetails>
//                </Accordion>
//            </div>
//        )
//    }
//}
const CustomAccordion = (props) => {
    return (
        <div>
             <Accordion>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                       aria-controls="panel1a-content"
                       id="panel1a-header"                   >
                    <Typography>{ props.title}</Typography>
                   </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={{wordBreak:"break-word"} } dangerouslySetInnerHTML={{ __html: props.text } }>
                       
                    </Typography>
                   </AccordionDetails>
               </Accordion>
            </div>
    )
}
export default CustomAccordion;