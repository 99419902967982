import React, { useState, useEffect } from "react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Alert from 'react-bootstrap/Alert';
import "./common.css";
import './Report.css';
import { INTERNAL_API_URL } from './config';

const AltriaLfpReport = () => {
    const getFileName = (response) => {
        let fileName = "Report.xlsx";
        let ar = response.headers.get("content-disposition")?.split(";").filter((x) => x && x.includes("filename="));
        if (ar.length && ar[0]) {
            let hdr = ar[0].trim();
            let index = hdr.indexOf("=");
            if (index !== -1)
                fileName = hdr.substring(index + 1);
        }

        return fileName;
    }

    const handleGenerateReport = async () => {
        setShowBlur(true);

        let url = INTERNAL_API_URL + "/Reports/DownloadAltriaLfpReport/" + end_date.toISOString();

        try {
            let response = await fetch(url);
            let contentType = response.headers.get("content-type");
            if (contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                let fileName = getFileName(response);

                let blob = await response.blob();

                const objectUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(objectUrl);
                setShowBlur(false);
                showSuccessAlert("Report file downloaded");
            } else {
                let text = await response.text();
                setShowBlur(false);
                showWarningAlert(text);
            }
        } catch (error) {
            setShowBlur(false);
            showErrorAlert(error.message);
        }
    };

    const showAlert = (text, variant) => {
        setAlertText(text);
        setAlertVariant(variant);
        setShowAlert(true);

        let timeout_id = setTimeout(() => {
            setShowAlert(false);
            setTimeoutId(null);
        }, 5000);
        setTimeoutId(timeout_id);
    }

    const showSuccessAlert = (text) => {
        showAlert(text, "success");
    }

    const showWarningAlert = (text) => {
        showAlert(text, "warning");
    }

    const showErrorAlert = (text) => {
        showAlert(text, "danger");
    }

    const [end_date, setEndDate] = useState(new Date());
    const [show_blur, setShowBlur] = useState(false);
    const [show_alert, setShowAlert] = useState(false);
    const [alert_variant, setAlertVariant] = useState("success");
    const [alert_text, setAlertText] = useState("");
    const [timeout_id, setTimeoutId] = useState(null);

    useEffect(() => {
        return () => {
            if (timeout_id)
                clearTimeout(timeout_id);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div style={{ width: "400px" }}>
            <div id="blur" style={{ display: show_blur ? "block" : "none" }}></div>
            <div className="control-group">
                <div className="control-title">End date</div>
                <DatePicker selected={end_date} dateFormat="MM/dd/yyyy" onChange={(date) => { setEndDate(date); }} />
            </div>
            <div>
                <button onClick={handleGenerateReport}>Generate report</button>
            </div>
            <Alert className="alert-msg" key={alert_variant} variant={alert_variant} show={show_alert} onClose={() => setShowAlert(false)} dismissible>{alert_text}</Alert>
        </div>
    );
}

export default AltriaLfpReport;